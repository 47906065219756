import React from 'react';
import './index.css';
import About from './components/About.jsx';
import Banner from './components/Banner.jsx';
import Contact from './components/Contact.jsx';
import Header from './components/Header.jsx';
import Nav from './components/Nav.jsx';
import Work from './components/Work.jsx';
import Footer from './components/Footer.jsx'
import { BrowserRouter, Routes, Route } from 'react-router-dom';

function App() {
  return (
    <div className='relative min-h-screen'>
      {/* Background with gradient and overlay */}
      <div className="fixed inset-0 bg-gradient-to-b from-gray-900 via-blue-900 to-purple-900" />
      <div className="fixed inset-0 bg-[radial-gradient(circle_at_center,rgba(66,108,245,0.1)_0%,rgba(0,0,0,0.6)_100%)]" />
      
      {/* Content */}
      <div className='relative z-10 min-h-screen flex flex-col'>
        <BrowserRouter>
          <Header />
          <main className="flex-grow">
            <Routes>
              <Route index path="/" element={<Banner />} />
              <Route path="/about" element={<About />} />
              <Route path="/work" element={<Work />} />
              <Route path="/contact" element={<Contact />} />
            </Routes>
          </main>
          <Nav />
          <Footer/>
        </BrowserRouter>
      </div>
    </div>
  );
}

export default App;
