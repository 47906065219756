import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { fadeIn } from '../variants';
import { TypeAnimation } from 'react-type-animation';

export default function Contact() {
  return (
    <section className="py-16 lg:section translate-y-[-30px]" id="contact">
      <div className="container mx-auto">
        <div className="flex flex-col lg:flex-row ">
          {/* text */}
          <div className="flex-1 flex justify-start items-center ">
            <motion.div
              variants={fadeIn('right', 0.3)}
              initial="hidden"
              whileInView={'show'}
              viewport={{ once: false, amount: 0.7 }} >
              <h4 className="text-xl font-medium mb-2 tracking-wide">
                Just a click away from working together!
              </h4>
              <h2 className="text-[45px] lg:text-[90px] leading-none mb-12">
                <TypeAnimation
                  sequence={[
                    'Get in touch',
                    1250,
                    'with Patrick',
                    750,
                    'right now!',
                    750,
                  ]}
                  speed={10}
                  className="theColorOfText"
                  wrapper="span"
                  repeat={Infinity}
                />
              </h2>
            </motion.div>
          </div>
          {/* form */}
            <motion.div variants={fadeIn('left', 0.2)}
              initial="hidden"
              whileInView={'show'}
              viewport={{ once: false, amount: 0.3 }}
              className='flex-1 '>
              <form
                name="Messag" method="POST"
                className="flex-1 rounded-2xl flex flex-col gap-y-3 pb-16 p-8 items-start text-[#7597de]"
              >
                <input type='hidden' name='form-name' value="Messag" />
                <input type="text" name="FirstName" required
                  className="bg-white rounded-md border-2 py-3 px-2 outline-none w-full placeholder:text-[#7597de] focus:[#00356B] transition-all"
                  placeholder="First Name" />
                <input type="text" name="LastName" required
                  className="bg-white rounded-md border-2 py-3 px-2 outline-none w-full placeholder:text-[#7597de] focus:[#00356B] transition-all"
                  placeholder="Last Name" />
                <input type="email" name="Email" required
                  className="bg-white rounded-md border-2 py-3 px-2 outline-none w-full placeholder:text-[#7597de] focus:[#00356B] transition-all"
                  placeholder="Email" />
                <textarea type="text" name="Message"
                  className="bg-white rounded-md border-2 px-2 py-12 outline-none w-full placeholder:text-[#7597de] focus:[#00356B] transition-all resize-none mb-12"
                  placeholder="Message" />
                <button type="submit" className='btn btn-lg lg:w-[250px]'>Send</button>
              </form>

            </motion.div>
        </div>
      </div>
    </section>
  );
}
