import React from 'react';
import { NavLink } from 'react-router-dom';
import { BiHomeAlt, BiUser } from 'react-icons/bi';
import { BsBriefcase, BsChatSquare } from 'react-icons/bs';

export default function Nav() {
  return (
    <nav className="fixed bottom-4 left-0 right-0 z-50 px-4 sm:px-6">
      <div className="container mx-auto max-w-md">
        <div className="bg-gray-900/80 backdrop-blur-xl rounded-2xl border border-gray-800/50 shadow-lg shadow-black/20">
          <div className="flex items-center justify-around p-2">
            <NavLink
              to="/"
              className={({ isActive }) => `
                flex flex-col items-center justify-center p-3 rounded-xl transition-all duration-300
                ${isActive 
                  ? 'text-blue-400 bg-white/10' 
                  : 'text-gray-400 hover:text-blue-400 hover:bg-white/5'
                }
              `}
            >
              <BiHomeAlt className="text-2xl" />
              <span className="text-xs mt-1">Home</span>
            </NavLink>

            <NavLink
              to="/about"
              className={({ isActive }) => `
                flex flex-col items-center justify-center p-3 rounded-xl transition-all duration-300
                ${isActive 
                  ? 'text-blue-400 bg-white/10' 
                  : 'text-gray-400 hover:text-blue-400 hover:bg-white/5'
                }
              `}
            >
              <BiUser className="text-2xl" />
              <span className="text-xs mt-1">About</span>
            </NavLink>

            <NavLink
              to="/work"
              className={({ isActive }) => `
                flex flex-col items-center justify-center p-3 rounded-xl transition-all duration-300
                ${isActive 
                  ? 'text-blue-400 bg-white/10' 
                  : 'text-gray-400 hover:text-blue-400 hover:bg-white/5'
                }
              `}
            >
              <BsBriefcase className="text-2xl" />
              <span className="text-xs mt-1">Work</span>
            </NavLink>

            <NavLink
              to="/contact"
              className={({ isActive }) => `
                flex flex-col items-center justify-center p-3 rounded-xl transition-all duration-300
                ${isActive 
                  ? 'text-blue-400 bg-white/10' 
                  : 'text-gray-400 hover:text-blue-400 hover:bg-white/5'
                }
              `}
            >
              <BsChatSquare className="text-2xl" />
              <span className="text-xs mt-1">Contact</span>
            </NavLink>
          </div>
        </div>
      </div>
    </nav>
  );
};