import React from 'react';
import { motion } from 'framer-motion';

const Timeline = ({ events }) => {
  return (
    <div className="relative w-full max-w-[800px] mx-auto">
      {/* Mobile Timeline (Single Column) */}
      <div className="lg:hidden space-y-6">
        {events.map((event, index) => (
          <motion.div
            key={`mobile-${event.year}-${event.period}`}
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: index * 0.1 }}
            viewport={{ once: true }}
            className="bg-gray-900/50 backdrop-blur-sm p-6 rounded-xl border border-gray-800 hover:border-blue-500/50 transition-all duration-300"
          >
            <div className="flex items-center gap-4 mb-3">
              <motion.div 
                className="w-10 h-10 rounded-full bg-gray-900 border-2 border-blue-500 flex items-center justify-center flex-shrink-0"
                animate={{
                  scale: [1, 1.1, 1],
                  borderColor: ['#3B82F6', '#8B5CF6', '#3B82F6'],
                }}
                transition={{
                  duration: 3,
                  repeat: Infinity,
                  ease: "easeInOut",
                }}
              >
                <div className="text-xl">{event.icon}</div>
              </motion.div>
              <div className="text-sm text-blue-400">
                {event.year} • {event.period}
              </div>
            </div>
            <h3 className="text-lg font-semibold text-white mb-2">{event.title}</h3>
            <p className="text-gray-400 text-sm">{event.description}</p>
          </motion.div>
        ))}
      </div>

      {/* Desktop Timeline (Two Columns) */}
      <div className="hidden lg:block relative">
        {/* Vertical Line with Gradient */}
        <div className="absolute left-1/2 transform -translate-x-1/2 w-0.5 h-full bg-gradient-to-b from-blue-400 via-purple-500 to-blue-600"></div>

        {/* Timeline Events */}
        <div className="relative">
          {events.map((event, index) => (
            <motion.div
              key={`desktop-${event.year}-${event.period}`}
              initial={{ opacity: 0, x: index % 2 === 0 ? -50 : 50 }}
              whileInView={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.5, delay: index * 0.2 }}
              viewport={{ once: true }}
              className={`flex items-center mb-16 ${
                index % 2 === 0 ? 'flex-row-reverse' : ''
              }`}
            >
              {/* Content */}
              <div className={`w-5/12 ${index % 2 === 0 ? 'text-right pr-8' : 'pl-8'}`}>
                <motion.div 
                  className="bg-gray-900/50 backdrop-blur-sm p-6 rounded-xl border border-gray-800 hover:border-blue-500/50 transition-all duration-300"
                  whileHover={{ y: -5 }}
                >
                  <h3 className="text-lg font-semibold text-white mb-2">
                    {event.title}
                  </h3>
                  <div className="text-sm text-blue-400 mb-3">
                    {event.year} • {event.period}
                  </div>
                  <p className="text-gray-400 text-sm leading-relaxed">
                    {event.description}
                  </p>
                </motion.div>
              </div>

              {/* Center Icon */}
              <div className="absolute left-1/2 transform -translate-x-1/2 flex items-center justify-center">
                <motion.div 
                  className="w-12 h-12 rounded-full bg-gray-900 border-2 border-blue-500 flex items-center justify-center shadow-lg"
                  animate={{
                    scale: [1, 1.2, 1],
                    borderColor: ['#3B82F6', '#8B5CF6', '#3B82F6'],
                    boxShadow: [
                      '0 0 0 0 rgba(59, 130, 246, 0)',
                      '0 0 0 10px rgba(59, 130, 246, 0.1)',
                      '0 0 0 0 rgba(59, 130, 246, 0)'
                    ]
                  }}
                  transition={{
                    duration: 3,
                    repeat: Infinity,
                    ease: "easeInOut",
                  }}
                >
                  <div className="text-2xl">
                    {event.icon}
                  </div>
                </motion.div>
              </div>
            </motion.div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Timeline; 