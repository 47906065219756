import React from 'react'
import Modal from './Pop-up'
import { motion } from 'framer-motion'
import { fadeIn } from '../variants.js'
import Image1 from '../Assets/C_PFood.png'; 
import Image2 from '../Assets/BlackJack.png'; 
import Image3 from '../Assets/Sudoku.png'
import Video2 from '../Assets/BlackJackApp.mp4'

export default function Work() {

  return (
    <div className=" sm:h-full lg:h-screen" id="work ">
      <div className="container p-10 mx-auto">
<div className='bg-black h2 theColorOfText flex lg:flex-col lg:px-20 translate-y-[-30px]'>
  <p>Projects</p>
</div>
<div className='flex flex-col lg:flex-row gap-x-4 gap-y-10 justify-center items-center '>

        <motion.div variants={fadeIn('right', 0.3)}
            initial="hidden"
            whileInView={'show'}
            viewport={{ once: false, amount: 0.1 }}
            className='flex flex-col justify-center items-center gap-y-16 '>
          
            {/* First project */}
              <Modal title = "Coming soon..."
                content = "1" 
                image={Image1}
                description={'Fitness Tracker'}
                />
            {/* Third */}
              <Modal  title = "BlackJack in JS, HTML, & CSS"
                content = "Holaaaa" 
                image={Image2}
                description={'BlackJack'}
                linkVideo={Video2}
                />

        </motion.div>
        <motion.div variants={fadeIn('left', 0.3)}
            initial="hidden"
            whileInView={'show'}
            viewport={{ once: false, amount: 0.1 }}
            className='flex flex-col justify-center items-center gap-y-16'>
          
            {/* Second */}
            <Modal  title = "Sudoku Project in Python"
                content = "Hola mi nombre es Patrick BLAH BLAH BLAH" 
                image={Image3} 
                description={'Sudoku'}
                />
            {/* Fourth */}
              <Modal  title = ""
                content = "4" 
                description={'ModPen'}
                />
        </motion.div>

</div>





      </div>
    </div>
  )
}
