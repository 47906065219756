import React from 'react'
import Image from '../Assets/ME.png'
import Resume from '../Assets/Patrick_Resume_2025.pdf'
import { FaGithub, FaLinkedin, FaFilePdf } from 'react-icons/fa'
import { TypeAnimation } from 'react-type-animation'
import { motion } from 'framer-motion'
import { fadeIn } from '../variants.js'
import { Link } from 'react-router-dom';

const Banner = () => {
  return (
    <section className="min-h-[calc(100vh-80px)] flex items-center py-12 lg:py-24 overflow-hidden">
      <div className="container mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex flex-col-reverse lg:flex-row items-center gap-8 lg:gap-12">
          {/* Text Content */}
          <motion.div
            variants={fadeIn('right', 0.3)}
            initial="hidden"
            whileInView="show"
            viewport={{ once: true }}
            className="flex-1 text-center lg:text-left pt-8 lg:pt-0"
          >
            {/* Name */}
            <motion.h1
              variants={fadeIn('up', 0.3)}
              initial="hidden"
              whileInView="show"
              viewport={{ once: true }}
              className="text-4xl sm:text-5xl lg:text-7xl font-bold mb-6 lg:mb-8 leading-tight"
            >
              <span className="bg-gradient-to-r from-blue-400 via-purple-500 to-blue-600 bg-clip-text text-transparent">
                PATRICK LEIMER
              </span>
            </motion.h1>

            {/* Animated Title */}
            <motion.div
              variants={fadeIn('up', 0.4)}
              initial="hidden"
              whileInView="show"
              viewport={{ once: true }}
              className="mb-6 lg:mb-8 text-xl sm:text-2xl lg:text-4xl font-semibold"
            >
              <span className="text-gray-300">I'm a </span>
              <TypeAnimation
                sequence={[
                  'Software Developer',
                  2000,
                  'Computer Scientist',
                  2000,
                  'UF Student',
                  2000,
                ]}
                speed={50}
                className="bg-gradient-to-r from-blue-400 to-purple-500 bg-clip-text text-transparent"
                wrapper="span"
                repeat={Infinity}
              />
            </motion.div>

            {/* Buttons and Social Links */}
            <motion.div
              variants={fadeIn('up', 0.5)}
              initial="hidden"
              whileInView="show"
              viewport={{ once: true }}
              className="flex flex-col sm:flex-row items-center justify-center lg:justify-start gap-4"
            >
              {/* Portfolio Button */}
              <Link
                to="/work"
                className="px-8 py-3 rounded-lg bg-gradient-to-r from-blue-500 to-purple-600 text-white font-medium hover:from-blue-600 hover:to-purple-700 transition-all duration-300 hover:shadow-lg hover:shadow-blue-500/25 w-full sm:w-auto text-center"
              >
                View Portfolio
              </Link>

              {/* Social Links */}
              <div className="flex items-center gap-6 text-2xl">
                <a
                  href="https://www.linkedin.com/in/Patrick-LeimerE/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-gray-400 hover:text-blue-400 transition-colors duration-300 group relative"
                >
                  <FaLinkedin />
                  <span className="absolute -top-8 left-1/2 transform -translate-x-1/2 bg-gray-900/90 text-gray-300 text-xs py-1 px-2 rounded opacity-0 group-hover:opacity-100 transition-opacity whitespace-nowrap">
                    LinkedIn Profile
                  </span>
                </a>
                <a
                  href="https://github.com/PatrickLeimer"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-gray-400 hover:text-purple-400 transition-colors duration-300 group relative"
                >
                  <FaGithub />
                  <span className="absolute -top-8 left-1/2 transform -translate-x-1/2 bg-gray-900/90 text-gray-300 text-xs py-1 px-2 rounded opacity-0 group-hover:opacity-100 transition-opacity whitespace-nowrap">
                    GitHub Profile
                  </span>
                </a>
                <a
                  href={Resume}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-gray-400 hover:text-pink-400 transition-colors duration-300 group relative"
                  download="Patrick_Leimer_Resume.pdf"
                >
                  <FaFilePdf />
                  <span className="absolute -top-8 left-1/2 transform -translate-x-1/2 bg-gray-900/90 text-gray-300 text-xs py-1 px-2 rounded opacity-0 group-hover:opacity-100 transition-opacity whitespace-nowrap">
                    Download Resume
                  </span>
                </a>
              </div>
            </motion.div>
          </motion.div>

          {/* Image */}
          <motion.div
            variants={fadeIn('down', 0.5)}
            initial="hidden"
            whileInView="show"
            viewport={{ once: true }}
            className="w-48 h-48 sm:w-56 sm:h-56 lg:w-[400px] lg:h-[400px] relative"
          >
            {/* Glowing background effect */}
            <div className="absolute inset-0 rounded-full bg-gradient-to-r from-blue-500/20 via-purple-500/20 to-blue-500/20 blur-2xl transform scale-110" />
            
            {/* Image container */}
            <div className="relative w-full h-full rounded-full border-2 border-gray-800/50 overflow-hidden bg-gradient-to-b from-gray-900 to-gray-800">
              <img
                src={Image}
                alt="Profile"
                className="w-full h-full object-cover object-center transform hover:scale-110 transition-transform duration-500"
              />
            </div>

            {/* Decorative ring */}
            <div className="absolute -inset-0.5 rounded-full bg-gradient-to-r from-blue-500 via-purple-500 to-blue-500 opacity-20 blur-sm group-hover:opacity-30 transition-opacity" />
          </motion.div>
        </div>
      </div>
    </section>
  );
};

export default Banner;
