import React from 'react'
import {
  FaHtml5,
  FaCss3Alt,
  FaJsSquare,
  FaReact,
  FaCuttlefish,
  FaDatabase, 
  FaGitAlt,
  FaPython,
  FaJava,
  FaChevronDown,
  FaArrowRight
} from 'react-icons/fa'
import { SiAdobeillustrator, SiMicrosoftoffice, SiVite } from 'react-icons/si'
import CountUp from 'react-countup'
import { useInView } from 'react-intersection-observer'
import { motion } from 'framer-motion'
import { fadeIn } from '../variants.js'
import Timeline from './Timeline'
import Marquee from 'react-fast-marquee'

const SkillIcon = ({ icon: Icon, name, color }) => (
  <motion.div 
    className="flex flex-col items-center gap-2 px-6"
    whileHover={{ scale: 1.1 }}
    whileTap={{ scale: 0.95 }}
  >
    <div className="text-4xl">
      {Icon}
    </div>
    <span className="text-xs font-medium text-gray-400 whitespace-nowrap">
      {name}
    </span>
  </motion.div>
);

const About = () => {
  const [ref, inView] = useInView({
    threshold: 0.5,
    triggerOnce: true
  })

  const skills = [
    { icon: <FaCuttlefish className="text-blue-400" />, name: "C" },
    { icon: <FaHtml5 className="text-orange-500" />, name: "HTML5" },
    { icon: <FaCss3Alt className="text-blue-500" />, name: "CSS3" },
    { icon: <FaJsSquare className="text-yellow-400" />, name: "JavaScript" },
    { icon: <FaPython className="text-yellow-400" />, name: "Python" },
    { icon: <FaJava className="text-red-400" />, name: "Java" },
    { icon: <FaReact className="text-blue-400" />, name: "React" },
    { icon: <FaDatabase className="text-green-400" />, name: "SQL" },
    { icon: <FaGitAlt className="text-orange-600" />, name: "Git" },
    { icon: <SiVite className="text-purple-400" />, name: "Vite" },
    { icon: <SiAdobeillustrator className="text-orange-400" />, name: "Illustrator" },
    { icon: <SiMicrosoftoffice className="text-blue-600" />, name: "Office" },
  ];

  const timelineEvents = [
    {
      year: 2022,
      period: 'Summer-Fall',
      title: 'C Programming',
      description: 'Foundations in systems programming and algorithms',
      icon: <FaCuttlefish className="text-blue-400" />
    },
    {
      year: 2023,
      period: 'Spring-Fall',
      title: 'Web Development',
      description: 'HTML, CSS, JavaScript, React, Tailwind',
      icon: <FaReact className="text-blue-400" />
    },
    {
      year: 2024,
      period: 'Spring',
      title: 'Python Development',
      description: 'Advanced programming concepts and data structures',
      icon: <FaPython className="text-yellow-400" />
    },
    {
      year: 2024,
      period: 'Summer',
      title: 'SWE Internship',
      description: 'Java & Salesforce Development',
      icon: <FaJava className="text-red-400" />
    },
    {
      year: 2026,
      period: 'Spring',
      title: 'Expected Graduation',
      description: 'University of Florida - Computer Science',
      icon: '🎓'
    }
  ]

  return (
    <section className="relative min-h-screen py-16 lg:py-24" id="about">
      {/* Scroll Indicator - Only visible on mobile */}
      <motion.div 
        className="lg:hidden fixed top-20 left-1/2 transform -translate-x-1/2 z-50"
        initial={{ opacity: 0, y: -20 }}
        animate={{ 
          opacity: 1, 
          y: 0,
        }}
        transition={{ duration: 0.5 }}
      >
        <div className="flex items-center gap-3 bg-gray-900/80 backdrop-blur-sm px-4 py-2 rounded-full border border-gray-800">
          <FaChevronDown className="text-blue-400 text-sm animate-bounce" />
          <span className="text-sm text-gray-400 whitespace-nowrap">Scroll to explore</span>
          <FaArrowRight className="text-blue-400 text-sm" />
        </div>
      </motion.div>

      <div className="container mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex flex-col lg:flex-row lg:items-start lg:gap-x-12">
          {/* Left Column - About Content */}
          <motion.div
            variants={fadeIn('right', 0.3)}
            initial="hidden"
            whileInView="show"
            viewport={{ once: true }}
            className="lg:w-1/2 mb-12 lg:mb-0"
          >
            <div className="max-w-xl">
              <h2 className="text-4xl lg:text-5xl font-bold mb-4 bg-gradient-to-r from-blue-400 to-purple-500 bg-clip-text text-transparent">
                About me
              </h2>
              <h3 className="text-2xl lg:text-3xl font-semibold mb-6 text-gray-200">
                Computer Science Student @ University of Florida
              </h3>
              <p className="text-gray-300 text-lg mb-8 leading-relaxed">
                Passionate about building innovative solutions and exploring new technologies. 
                My journey in computer science has equipped me with a strong foundation in 
                both theoretical concepts and practical applications. I specialize in full-stack 
                development and systems programming, with a keen interest in creating efficient 
                and scalable solutions.
              </p>
              
              {/* Graduation Counter */}
              <div className="mb-10" ref={ref}>
                <div className="bg-gray-900/50 backdrop-blur-sm p-6 rounded-xl border border-gray-800 hover:border-blue-500/50 transition-colors duration-300">
                  <div className="font-primary text-sm tracking-[2px] font-semibold text-gray-400 mb-2">
                    Expected Graduation
                  </div>
                  <div className="text-4xl lg:text-5xl font-bold bg-gradient-to-r from-blue-400 to-purple-500 bg-clip-text text-transparent">
                    {inView ? (
                      <CountUp
                        separator=""
                        start={2000}
                        end={2026}
                        duration={3}
                      />
                    ) : null}
                  </div>
                </div>
              </div>

              {/* Skills Section */}
              <div className="relative bg-gray-900/50 backdrop-blur-sm p-6 rounded-xl border border-gray-800 hover:border-blue-500/50 transition-all duration-300 group">
                <div className="flex justify-between items-center mb-6">
                  <h4 className="text-sm tracking-[2px] font-semibold text-gray-400">
                    Technical Skills
                  </h4>
                  <div className="flex items-center gap-2 text-xs text-gray-500">
                    <FaArrowRight className="animate-pulse" />
                    <span>Slide to explore</span>
                  </div>
                </div>
                <div className="relative">
                  {/* Gradient Overlays */}
                  <div className="absolute inset-y-0 left-0 w-20 bg-gradient-to-r from-gray-900 to-transparent z-10" />
                  <div className="absolute inset-y-0 right-0 w-20 bg-gradient-to-l from-gray-900 to-transparent z-10" />
                  
                  <Marquee
                    pauseOnHover={true}
                    speed={40}
                    gradient={false}
                    className="overflow-hidden py-4"
                  >
                    <div className="flex items-center gap-4">
                      {skills.map((skill, index) => (
                        <SkillIcon 
                          key={index}
                          icon={skill.icon}
                          name={skill.name}
                        />
                      ))}
                    </div>
                  </Marquee>
                </div>
              </div>
            </div>
          </motion.div>

          {/* Right Column - Timeline */}
          <motion.div
            variants={fadeIn('left', 0.5)}
            initial="hidden"
            whileInView="show"
            viewport={{ once: true }}
            className="lg:w-1/2"
          >
            <Timeline events={timelineEvents} />
          </motion.div>
        </div>
      </div>
    </section>
  )
}

export default About
