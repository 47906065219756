import React from 'react'
//images
import Logo from '../Assets/PLdev.png';
import { Link } from 'react-router-dom';

export default function Header() {
  return (
    <header className='sticky top-0 z-50 backdrop-blur-lg bg-gray-900/30 border-b border-gray-800/50'>
      <div className='container mx-auto px-4 sm:px-6 lg:px-8 py-4'>
        <div className='flex justify-between items-center'>
          {/* Logo Section */}
          <Link to="/" className="flex items-center space-x-2 hover:opacity-80 transition-opacity">
            <img 
              src={Logo} 
              alt="PLdev Logo"
              className='w-12 h-12 object-contain'
            />
            <span className="font-bold text-xl bg-gradient-to-r from-blue-400 to-purple-500 bg-clip-text text-transparent hidden sm:block">
              PLdev
            </span>
          </Link>

          {/* Contact Button */}
          <Link
            to="/contact"
            className="inline-flex items-center px-4 py-2 rounded-lg bg-gradient-to-r from-blue-500 to-purple-600 text-white font-medium text-sm hover:from-blue-600 hover:to-purple-700 transition-all duration-300 hover:shadow-lg hover:shadow-blue-500/25"
          >
            Contact Me
          </Link>
        </div>
      </div>
    </header>
  )
}
